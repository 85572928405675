import { FunctionComponent, Ref } from 'react';
import { Control, useController } from 'react-hook-form';

import { TextField } from '@limepayments/cosmic';

export interface InputProps {
  id?: string;
  control: Control;
  name:
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'address'
    | 'dateOfBirth'
    | 'phoneNumber'
    | 'emailAddress'
    | 'passportNumber'
    | 'passportCountry'
    | 'dlNumber'
    | 'dlState';
  label: string;
  defaultValue: string;
  rules?: object;
  inputRef?: Ref<any>;
  inputComponent?: FunctionComponent;
  type?: string;
  required?: boolean;
  testId?: string;
  placeholder?: string;
  autoFocus?: boolean;
}

const Input = (props: InputProps) => {
  const { label, inputRef, inputComponent, type, required, testId, placeholder, autoFocus, ...otherProps } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController(otherProps);

  return (
    <TextField
      {...inputProps}
      inputRef={inputRef}
      label={label}
      placeholder={placeholder}
      required={required}
      error={error?.message ?? ''}
      type={type ?? 'text'}
      helperText={error ? error.message : ''}
      testId={testId}
      autoFocus={autoFocus ?? false}
    />
  );
};

export default Input;
