import { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';

import { registrationReducer } from './reducers';
import { Registration, RegistrationContextType } from './types';

export interface RegistrationProviderProps {
  initialState?: RegistrationContextType;
  children: ReactNode;
}

const RegistrationContext = createContext<{ state: Registration; dispatch: Dispatch<any> } | undefined>(undefined);

function RegistrationProvider({ initialState, children }: RegistrationProviderProps) {
  const [state, dispatch] = useReducer(registrationReducer, initialState);
  const value = { state, dispatch };

  return <RegistrationContext.Provider value={value}>{children}</RegistrationContext.Provider>;
}

function useRegistration() {
  const context = useContext(RegistrationContext);
  if (context === undefined) {
    throw new Error('Registration context is required');
  }
  return context;
}

export { RegistrationProvider, useRegistration };
