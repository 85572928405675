import { FunctionComponent, Ref } from 'react';
import { Control, useController } from 'react-hook-form';

import { TextField } from '@limepayments/cosmic';

export interface InputProps {
  control: Control;
  name: 'phoneVerificationCode' | 'emailVerificationCode';
  label: string;
  defaultValue: string;
  rules?: object;
  inputRef?: Ref<any>;
  inputComponent?: FunctionComponent;
  testId?: string;
  autoFocus?: boolean;
}

const Input = (props: InputProps) => {
  const { label, inputRef, inputComponent, testId, autoFocus, ...otherProps } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController(otherProps);

  return (
    <TextField
      {...inputProps}
      inputRef={inputRef}
      label={label}
      error={error?.message ?? ''}
      helperText={error?.message ?? ''}
      testId={testId}
      maxLength={6}
      minLength={6}
      inputMode="numeric"
      autoFocus={autoFocus ?? false}
    />
  );
};

export default Input;
