import { Order } from 'lib/types';

export const PAYMENT_TITLE = 'How do you want to make your payment today?';

export const getCustomizations = (
  isLaddrB2C: boolean,
  isLaddrTF: boolean,
  order: Order | null,
  merchantBusinessDisplayName: string,
) => {
  if (isLaddrTF) {
    return {
      orderTitle: 'Hi there, \nhere are the details of your order',
      totalAmount: 'Order Total',
    };
  }

  if (isLaddrB2C) {
    return {
      orderTitle: 'Hi there, \nhere are the details of your order',
      totalAmount: 'Order Total (includes GST)',
    };
  }

  return {
    orderTitle: `Hi ${
      order?.customerName || 'there'
    }, \nhere are the payment details for ${merchantBusinessDisplayName}.`,
    totalAmount: 'Total',
  };
};
