import styled from '@emotion/styled';

export const MobileInputWrapper = styled.div`
  .PhoneInputCountry {
    display: none;
  }
  .MuiFormHelperText-root.Mui-error {
    color: #d3354a;
    font-size: 12px;
    font-family: Geograph, san-serif;
  }
  .MuiInput-underline {
    &.Mui-error:after {
      border-bottom-color: #d3354a;
    }
    &:before {
      border-bottom: 1px solid #e0e1ea;
    }
    &:after {
      border-bottom: 2px solid #6f45c9;
    }
    &:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #e0e1ea;
    }
  }
  .MuiFormLabel-root {
    color: #555c7c;
    font-size: 16px;
    font-family: Geograph, san-serif;
    letter-spacing: 0.25px;
    line-height: 24px;
    top: -5px;
    &.Mui-error {
      color: #d3354a;
      font-size: 16px;
      font-family: Geograph, san-serif;
    }
    &.Mui-focused {
      color: #6f45c9;
      top: 0px;
    }
  }

  .MuiInput-root > .MuiInput-input {
    font-size: 16px;
    font-family: Geograph, san-serif;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 24px;
    color: #191e33;
    padding-bottom: 16px;
  }
  .MuiTextField-root {
    height: 56px;
  }
  .MuiInputBase-root {
    color: #555c7c;
  }
  .registrationMobileNumber.hasInput .MuiFormLabel-root {
    top: 0px;
  }
`;

export const MessageBar = styled.div`
  margin: 10px 0;
`;

export const Form = styled.form`
  margin-top: 15px;
`;

export const GoogleAddress = styled.div`
  .MuiInput-root > .MuiInput-input {
    margin-bottom: 48px;
    padding-bottom: 2px !important;
    line-height: 24px !important;
  }

  .pac-target-input:not(:-webkit-autofill) {
    animation-name: mui-auto-fill-cancel;
  }
`;

export const Wrapper = styled.div`
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputAdornment-root svg {
    display: none;
  }
`;
