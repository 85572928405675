import { getOrderInvoice, getPublicOrderCustomSettings } from 'api/api';
import { useConfig } from 'lib/configContext';
import { useOrder } from 'lib/orderContext';
import { SET_CONFIG, SET_ORDER_DETAILS, SET_STEP } from 'lib/reducers';
import { ORDER_STATUS } from 'lib/types';
import { INSTORE_STEPS, toCurrency } from 'lib/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Heading, Icon, LabelledValue, Message, Text, Tooltip } from '@limepayments/cosmic';

import { getCustomizations } from './Constants';
import * as s from './Order.styles';
import Spinner from './Spinner';

const InfoIcon = () => (
  <s.InfoWrapper>
    <Icon name="Info" />
  </s.InfoWrapper>
);

export interface OrderLineProps {
  sku: string;
  description: string;
  amountString: string;
  index: number;
}

type VisibleMeta = {
  label: string;
  value: string;
};

const Order = () => {
  const { orderId } = useParams<'orderId'>();
  const [visibleMeta, setVisibleMeta] = useState<Array<VisibleMeta>>([]);

  const {
    state: {
      apiBaseUri,
      merchantId,
      branding,
      token,
      isLaddrB2C,
      isLaddrTF,
      merchantPublicKey,
      merchantBusinessDisplayName,
      surchargeQuoteRequired,
    } = {
      apiBaseUri: '',
      merchantId: '',
      branding: undefined,
      token: '',
      isLaddrB2C: null,
      isLaddrTF: null,
      merchantPublicKey: '',
      merchantBusinessDisplayName: '',
      surchargeQuoteRequired: false,
    },
    dispatch: dispatchConfig,
  } = useConfig();

  const { state: orderState, dispatch: dispatchOrder } = useOrder();

  const [isOrderRetrieved, setIsOrderRetrieved] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderError, setOrderError] = useState<string | null>(null);
  const [orderStatusError, setOrderStatusError] = useState<string | null>(null);

  const customizations = useMemo(
    () => getCustomizations(!!isLaddrB2C, !!isLaddrTF, orderState ?? null, merchantBusinessDisplayName),
    [isLaddrB2C, isLaddrTF, orderState, merchantBusinessDisplayName],
  );

  const onProceedPayment = useCallback(() => {
    dispatchConfig({
      type: SET_STEP,
      payload: INSTORE_STEPS.Payment,
    });
  }, [dispatchConfig]);

  useEffect(() => {
    (async () => {
      if (!apiBaseUri || !orderId || !merchantId || !merchantPublicKey) {
        return false;
      }

      try {
        setIsLoading(true);
        const [_order, { customFields }] = await Promise.all([
          getOrderInvoice(apiBaseUri, token, merchantId, orderId),
          getPublicOrderCustomSettings(apiBaseUri, merchantPublicKey, merchantId),
        ]);

        const {
          customToken,
          hidePayLaterOption,
          order: {
            status,
            items,
            amount,
            paymentTypeAmounts,
            currency,
            description,
            taxIncludedAmount,
            customerName,
            customerEmail,
            phoneNo,
            request3DSOnPayment,
            metadata,
          },
        } = _order;

        const _visibleMeta: Array<VisibleMeta> = [];
        if (Array.isArray(customFields) && customFields.length > 0 && metadata) {
          for (const field of customFields) {
            if (field.visibleForCustomer === false) {
              continue;
            }
            const obj = {
              label: field.label,
              value: metadata[field.key],
            };
            _visibleMeta.push({ ...obj });
          }
        }
        setVisibleMeta(_visibleMeta);

        setIsLoading(false);
        dispatchConfig({
          type: SET_CONFIG,
          payload: {
            customToken,
          },
        });
        dispatchOrder({
          type: SET_ORDER_DETAILS,
          payload: {
            status,
            amount,
            paymentTypeAmounts,
            items,
            currency,
            description,
            taxIncludedAmount,
            customerName,
            customerEmail,
            phoneNo,
            request3DSOnPayment,
            hidePayLaterOption,
          },
        });
      } catch (e) {
        setOrderError(e.message);
        setIsLoading(false);
      }
    })();
  }, [orderId, token, dispatchOrder, apiBaseUri, merchantId, dispatchConfig, merchantPublicKey]);

  useEffect(() => {
    if (orderState && orderState.amount) {
      setIsOrderRetrieved(true);
      if (orderState.status !== ORDER_STATUS.Created) {
        setOrderStatusError(orderState.status);
      }
    }
  }, [orderState]);

  return (
    <s.OrderPaymentWrapper>
      <s.Header>
        <img className="img" src={branding?.logoUri} alt={branding?.logoAltText} />
      </s.Header>
      <s.Main>
        {orderError && (
          <s.Alert>
            <Message dismissalType="manual" type="toast" variant="error">
              <Heading variant="xxs" className="messageHeading">
                Error
              </Heading>
              <Text variant="caption" className="messageText">
                {orderError}
              </Text>
            </Message>
          </s.Alert>
        )}
        {orderStatusError && (
          <s.Alert>
            <Message dismissalType="manual" type="toast" variant="error">
              <Heading variant="xxs" className="messageHeading">
                Error
              </Heading>
              <Text variant="caption" className="messageText">
                Order has been {orderStatusError}
              </Text>
            </Message>
          </s.Alert>
        )}

        {!isLoading && !orderStatusError && (
          <Heading variant="md" className="heading">
            {customizations.orderTitle}
          </Heading>
        )}

        {isLoading ? (
          <s.SpinnerWrapper>
            <Spinner color="#007BFF" />
          </s.SpinnerWrapper>
        ) : (
          <>
            {orderState?.status === ORDER_STATUS.Created && (
              <>
                {orderState?.description.length > 0 && (
                  <s.DetailsWrapper>
                    <Text className="details-heading" variant="body-2" isEmphasised>
                      Description
                    </Text>
                    <Text variant="body-3">{orderState.description}</Text>
                  </s.DetailsWrapper>
                )}
                {(orderState.phoneNo || orderState.customerName || orderState.customerEmail) && (
                  <s.DetailsWrapper>
                    <Text className="details-heading customer-details" variant="body-2" isEmphasised>
                      Details
                    </Text>
                    {orderState.customerName && <Text variant="body-3">{orderState.customerName}</Text>}
                    {orderState.customerEmail && <Text variant="body-3">{orderState.customerEmail}</Text>}
                    {orderState.phoneNo && <Text variant="body-3">{orderState.phoneNo}</Text>}
                    {visibleMeta.map((meta, index) => (
                      <s.MetaWrapper key={index}>
                        <LabelledValue
                          testId={`meta-${meta.label}`}
                          label={meta.label}
                          value={meta.value}
                          variant="horizontal"
                        />
                      </s.MetaWrapper>
                    ))}
                  </s.DetailsWrapper>
                )}
                <s.DetailsWrapper>
                  <Text className="details-heading" variant="body-2" isEmphasised>
                    Payment details
                  </Text>
                  {!!orderState.items.length && (
                    <s.ItemsWrapper>
                      {orderState.items.map(({ description, quantity, amount }, index) => (
                        <s.ListItem key={index}>
                          <Text variant="body-3" testId={`item${index}Description`}>
                            {description} ({quantity})
                          </Text>
                          <Text className="cost" variant="body-3" testId={`item${index}Price`}>
                            {toCurrency(amount * quantity, true)}
                          </Text>
                        </s.ListItem>
                      ))}
                    </s.ItemsWrapper>
                  )}
                  {!!orderState.taxIncludedAmount && (
                    <s.ListItem>
                      <Text variant="body-3" testId="totalIncludesGst">
                        GST
                      </Text>
                      <Text className="cost" variant="body-3" testId="gstAmount">
                        {toCurrency(orderState.taxIncludedAmount, true)}
                      </Text>
                    </s.ListItem>
                  )}
                  <s.ListItem>
                    <Text variant="body-3" isEmphasised testId="total">
                      {customizations.totalAmount}
                    </Text>
                    <Text className="cost" variant="body-3" isEmphasised testId="totalPrice">
                      {toCurrency(orderState.amount, true)}
                    </Text>
                  </s.ListItem>
                  {!!surchargeQuoteRequired && (
                    <s.SurchargeWrapper>
                      <Text variant="caption">Surcharge may apply</Text>
                      <Tooltip tooltip="Surcharge is calculated at the last step of the checkout and costs may vary by payment method selected.">
                        <InfoIcon />
                      </Tooltip>
                    </s.SurchargeWrapper>
                  )}
                </s.DetailsWrapper>
              </>
            )}
          </>
        )}

        {orderState?.status === 'created' && (
          <s.Cta>
            <Button
              variant="primary"
              size="large"
              className="lp-w-full"
              disabled={!isOrderRetrieved}
              onClick={onProceedPayment}
              testId="proceedToPaymentBtn"
            >
              Proceed to payment
            </Button>
          </s.Cta>
        )}
      </s.Main>
    </s.OrderPaymentWrapper>
  );
};

export default Order;
