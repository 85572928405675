import styled from '@emotion/styled';

export const VerifyWrapper = styled.div`
  .heading {
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
  .text {
    color: #555c7c;
  }
  button.go-back-btn {
    color: #191e33;

    &:hover {
      color: #191e33;
    }
  }
`;

export const ResendLink = styled.div`
  margin-top: 12px;
  position: absolute;
`;

export const MessageBar = styled.div`
  margin: 15px 0;
`;

export const InputWrapper = styled.div<{ top: string }>`
  display: block;
  position: relative;
  margin-top: ${({ top }) => top};
  padding-bottom: 72px;

  .MuiFormControl-root {
    width: 100%;
  }
`;
