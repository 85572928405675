import Instore from './components/Instore';
import { ConfigProvider } from './lib/configContext';
import { OrderProvider } from './lib/orderContext';
import { RegistrationProvider } from './lib/registrationContext';

function InstoreApp() {
  return (
    <ConfigProvider>
      <RegistrationProvider>
        <OrderProvider>
          <Instore />
        </OrderProvider>
      </RegistrationProvider>
    </ConfigProvider>
  );
}

export default InstoreApp;
