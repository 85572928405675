import { createOrGetCustomer, sendCustomerVerification } from 'api/api';
import { useConfig } from 'lib/configContext';
import { SET_REGISTRATION_FORM, SET_STEP } from 'lib/reducers';
import { useRegistration } from 'lib/registrationContext';
import scrollToView from 'lib/scrollToView';
import { INSTORE_STEPS } from 'lib/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';

import { Loader } from '@googlemaps/js-api-loader';
import { Button, Heading, Link, Message, Stack, Text } from '@limepayments/cosmic';

import Date from './Date';
import PhoneNumber from './PhoneNumber';
import * as s from './Register.styles';
import Input from './RegisterInput';
import { validateAge, validateDob, validateEmail, validateMobileNumber } from './RegisterUtils';

export interface RegistrationData {
  emailAddress: string;
  phoneNumber: string;
}

const Register = () => {
  const {
    state: { apiBaseUri, merchantPublicKey, authApiKey, tcUrl, merchantTradingCountry },
    dispatch: dispatchConfig,
  } = useConfig();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { state: registrationState, dispatch: dispatchRegistration } = useRegistration();

  const [addressIdentifier, setAddressIdentifier] = useState<string | null>(null);

  const { setValue, handleSubmit, control } = useForm();

  const onSubmit = async (data: RegistrationData) => {
    if (!merchantPublicKey) {
      return;
    }

    try {
      setLoading(true);
      const customerId = await createOrGetCustomer(apiBaseUri, merchantPublicKey, {
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
      });

      const { emailVerification, phoneVerification } = await sendCustomerVerification(
        apiBaseUri,
        merchantPublicKey,
        customerId,
        {
          emailAddress: data.emailAddress,
          phoneNumber: data.phoneNumber,
        },
      );

      if (emailVerification && phoneVerification) {
        dispatchConfig({
          type: SET_STEP,
          payload: INSTORE_STEPS.Verify,
        });
      }

      dispatchRegistration({
        type: SET_REGISTRATION_FORM,
        payload: { ...data, addressIdentifier, customerId, emailVerification, phoneVerification },
      });
    } catch (e) {
      setErrorMsg(e.message);
      setLoading(false);
      scrollToView('.error-msg');
    }
  };

  const addressRef = useRef<HTMLInputElement | null>(null);

  const mountAddressAutocomplete = useCallback(
    (input: HTMLInputElement, onPlaceChanged: (autocomplete: google.maps.places.Autocomplete) => void) => {
      if (!merchantTradingCountry) {
        return;
      }
      const country = merchantTradingCountry.toLowerCase();
      const loader = new Loader({
        apiKey: authApiKey,
        libraries: ['places'],
      });
      loader.load().then(() => {
        const autocomplete = new google.maps.places.Autocomplete(input, {
          types: ['address'],
          fields: ['place_id', 'formatted_address'],
          componentRestrictions: { country },
        });
        autocomplete.addListener('place_changed', () => onPlaceChanged(autocomplete));
      });
    },
    [authApiKey, merchantTradingCountry],
  );

  useEffect(() => {
    if (addressRef.current) {
      mountAddressAutocomplete(addressRef.current, (autocomplete) => {
        const { formatted_address, place_id } = autocomplete.getPlace();
        setValue('address', formatted_address || '');
        setAddressIdentifier(place_id || null);
      });
    }
  }, [mountAddressAutocomplete, setValue]);

  useEffect(() => {
    dispatchRegistration({
      type: SET_REGISTRATION_FORM,
      payload: {},
    });
  }, [dispatchRegistration]);

  if (!merchantTradingCountry) {
    return <></>;
  }

  return (
    <s.Wrapper>
      <Heading variant="lg">Register now to pay in instalments</Heading>

      {errorMsg.length > 0 && (
        <s.MessageBar className="error-msg">
          <Message type="inline" variant="error" dismissalType="none">
            <Text testId="registerErrorMsg">{errorMsg}</Text>
          </Message>
        </s.MessageBar>
      )}

      <s.Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="sm">
          <Input
            control={control}
            name="firstName"
            label="First name"
            defaultValue={registrationState?.firstName ?? ''}
            rules={{ required: 'Required' }}
            testId="firstName"
            autoFocus
          />

          <Input
            control={control}
            name="middleName"
            label="Middle name(s)"
            defaultValue={registrationState?.middleName ?? ''}
            testId="middleName"
          />

          <Input
            control={control}
            name="lastName"
            label="Last name"
            defaultValue={registrationState?.lastName ?? ''}
            rules={{ required: 'Required' }}
            testId="lastName"
          />

          <s.GoogleAddress>
            <Input
              control={control}
              name="address"
              label="Residential address"
              defaultValue={registrationState?.address ?? ''}
              rules={{ required: 'Required' }}
              inputRef={addressRef}
              testId="address"
            />
          </s.GoogleAddress>

          <Date
            control={control}
            name="dateOfBirth"
            label="Date of birth"
            defaultValue={registrationState?.dateOfBirth ?? ''}
            placeholder="DD/MM/YYYY"
            rules={{ required: 'Required', validate: { validDate: validateDob, validAge: validateAge } }}
            testId="dateOfBirth"
          />

          <s.MobileInputWrapper>
            <Controller
              name="phoneNumber"
              defaultValue={registrationState?.phoneNumber ?? ''}
              control={control}
              rules={{ required: 'Required', validate: { validMobileNumber: validateMobileNumber } }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <PhoneInput
                  className={`registrationMobileNumber ${value?.length > 0 ? 'hasInput' : ''}`}
                  defaultCountry={merchantTradingCountry}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  inputComponent={PhoneNumber}
                  autoComplete="off"
                  data-test-id="phoneNumber"
                />
              )}
            />
          </s.MobileInputWrapper>

          <Input
            control={control}
            name="emailAddress"
            label="Email"
            type="email"
            defaultValue={registrationState?.emailAddress ?? ''}
            rules={{ required: 'Required', validate: { validEmail: validateEmail } }}
            testId="email"
          />

          <Text variant="legal">
            By continuing, you consent to your personal information being disclosed and used to help check and verify
            your identity. April collect and use the information you provide to administer your payment plan on behalf
            of the merchant. It is a condition of the payment plan that you provide this information. You can check the
            information we hold about you at any time. You can learn more by reading our{' '}
            <Link size="legal" href="https://meetapril.com/privacy/" target="_blank">
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link size="legal" href={tcUrl} target="_blank">
              Terms of Use
            </Link>
          </Text>

          <div>
            <Button
              isLoading={loading}
              disabled={loading}
              className="lp-w-full"
              variant="primary"
              size="large"
              testId="submitButton"
            >
              Submit
            </Button>
          </div>
        </Stack>
      </s.Form>
    </s.Wrapper>
  );
};

export default Register;
