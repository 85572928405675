import { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';

import { configReducer } from './reducers';
import { Config, Context } from './types';

export interface ConfigProviderProps {
  initialState?: Context;
  children: ReactNode;
}

const ConfigContext = createContext<{ state: Config; dispatch: Dispatch<any> } | undefined>(undefined);

function ConfigProvider({ initialState, children }: ConfigProviderProps) {
  const [state, dispatch] = useReducer(configReducer, initialState);
  const value = { state, dispatch };

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
}

function useConfig() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('Config context is required');
  }
  return context;
}

export { ConfigProvider, useConfig };
