import React from 'react';

import TextField from '@material-ui/core/TextField';

const phoneNumber = (props: any, ref: React.Ref<any>) => {
  const { error: errorMsg, ...otherProps } = props;

  return (
    <TextField
      {...otherProps}
      inputRef={ref}
      fullWidth
      size="small"
      name="mobileNumber"
      label="Mobile Number"
      variant="standard"
      error={!!errorMsg}
      helperText={props.error}
    />
  );
};

export default React.forwardRef(phoneNumber);
