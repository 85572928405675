import { ActionType, Order, Registration, State } from './types';

export const SET_CONFIG = 'SET_CONFIG';
export const SET_STEP = 'SET_STEP';
export const SET_REGISTRATION_FORM = 'SET_REGISTRATION_FORM';
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';

function configReducer(state: State, action: ActionType) {
  switch (action.type) {
    case SET_CONFIG: {
      return { ...state, ...action.payload };
    }
    case SET_STEP: {
      return { ...state, step: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function orderReducer(state: Order, action: ActionType) {
  switch (action.type) {
    case SET_ORDER_DETAILS: {
      return { ...state, ...action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function registrationReducer(state: Registration, action: ActionType) {
  switch (action.type) {
    case SET_REGISTRATION_FORM: {
      return { ...state, ...action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export { configReducer, registrationReducer, orderReducer };
