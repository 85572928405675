export type PaymentType = 'paycard' | 'payplan';

export type Item = {
  amount: number;
  quantity: number;
  description: string;
  sku: string;
};

export interface Config {
  apiBaseUri: string;
  authApiKey: string;
  authDomain: string;
  customToken: string;
  merchantId: string;
  tcUrl: string;
  merchantPublicKey: string;
  token: string;
  step: number;
  branding: {
    logoUri: string;
    logoAltText: string;
    iconUri: string;
    primaryColor?: string;
  };
  tenantId: string;
  merchantTradingCountry: 'AU' | 'NZ';
  isLaddrB2C: boolean | null;
  isLaddrTF: boolean | null;
  merchantBusinessDisplayName: string;
  surchargeQuoteRequired?: boolean;
  useCheckoutV2: boolean;
}

export type Order = {
  status: ORDER_STATUS;
  items: Item[];
  amount: number;
  paymentTypeAmounts?: Array<{
    checkoutPayType: PaymentType;
    amount: number;
  }>;
  currency: string;
  description: string;
  taxIncludedAmount: number | null;
  customerName: string | null;
  customerEmail: string | null;
  phoneNo: string | null;
  request3DSOnPayment: boolean;
  hidePayLaterOption?: boolean;
};

type EmailVerificationType = {
  customerId: string;
  emailAddress: string;
  createdAt: string;
};

type PhoneVerificationType = {
  customerId: string;
  phoneNumber: string;
  createdAt: string;
};

type IdDocOptionsType = {
  idDocType: 'driverslicence' | 'passport';
  remainingAttempts: number;
};

type PayTypeEligibility = {
  eligibility: 'allow' | 'deny' | 'idwithoutdocument' | 'idwithdocument';
  message: string | null;
  idDocOptions: IdDocOptionsType[];
};

export type Registration = {
  emailAddress: string;
  phoneNumber: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  address: string;
  addressIdentifier: string;
  customerId: string;
  emailVerification: EmailVerificationType;
  phoneVerification: PhoneVerificationType;
  payTypeEligibility?: PayTypeEligibility;
  passportNumber?: string;
  passportCountry?: string;
  dlNumber?: string;
  dlState?: string;
};

export type Context = {
  instore: Config;
  setInstore: (newState: Config) => void;
};

export type OrderContextType = {
  instore: Order;
  setInstore: (newState: Order) => void;
};

export type RegistrationContextType = {
  instore: Registration;
  setInstore: (newState: Registration) => void;
};

export type ActionType = {
  type: string;
  payload: any;
};

export interface State {
  tcUrl: string;
  authApiKey: string;
  merchantPublicKey: string;
  step: number;
  branding: {
    logoUri: string;
    logoAltText: string;
  };
}

export type CheckoutEventType = {
  eventData: any;
  eventName: string;
};

export type CheckoutErrorEventType = {
  message: string;
};

export enum PAY_TYPE {
  Payplan = 'payplan',
  Paycard = 'paycard',
}

export enum ORDER_STATUS {
  Created = 'created',
  Paid = 'paid',
  Cancelled = 'cancelled',
  Fulfilled = 'fulfilled',
  Returned = 'returned',
}
