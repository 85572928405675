export interface ErrorResponse {
  statusCode: number;
  errorCode: string;
  message: string;
  tracer?: string;
  detail?: string;
  errorTypeDescription?: string;
}

export class ApiError extends Error {
  payload: ErrorResponse;

  constructor(message: string, payload: ErrorResponse) {
    super(message);

    this.name = 'ApiError';
    this.payload = payload;

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export function isApiError(error: unknown): error is ApiError {
  return error instanceof ApiError;
}

export const handleResponse = async <T = any>(
  res: Response,
  getApiErrorMessage?: (error: ErrorResponse) => string,
): Promise<T> => {
  const body = await res.text();

  if (res.ok) {
    // success response
    return body.length ? JSON.parse(body) : body;
  }

  // error response
  let payload: ErrorResponse | null = null;

  try {
    // attempt to parse json error
    payload = JSON.parse(body);
  } catch (error) {}

  if (payload) {
    throw new ApiError(getApiErrorMessage?.(payload) ?? payload.message, payload);
  } else {
    throw Error(body);
  }
};

// TODO: Get merchant name after url slug is finalised
export const getMerchantName = () => window.location.pathname.split('/')[1];
