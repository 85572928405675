import styled from '@emotion/styled';

export const OrderPaymentWrapper = styled.div`
  background-color: white;
  .heading {
    margin-bottom: 24px;
    white-space: pre-wrap;
  }
`;

export const Header = styled.header`
  padding: 15px 22px;
  min-height: 55px;
  box-shadow: var(--lp-shadows-medium);
  .img {
    width: auto;
    height: auto;
    max-height: 40px;
  }
`;

export const Main = styled.div`
  max-width: 768px;
  margin: 0 auto;
  padding: 24px 16px;
`;

export const Cta = styled.div`
  margin: 16px 0 0;
`;

export const DetailsWrapper = styled.div`
  color: rgb(var(--lp-colors-neutral-600));
  border: 1px solid rgb(var(--lp-colors-neutral-400));
  border-radius: 4px;
  padding: 24px 18px;
  margin-top: 16px;

  .details-heading {
    color: rgb(var(--lp-colors-neutral-800));
  }
`;

export const ItemsWrapper = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid #bababa;
`;

export const ListItem = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: center;
  .cost {
    margin: 0 0 0 auto;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Alert = styled.div`
  margin-bottom: 16px;
`;

export const MetaWrapper = styled.div`
  margin-top: 8px;
  span {
    color: rgb(var(--lp-colors-neutral-600));
  }
`;
export const SurchargeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  padding: 4px 8px;
  background: rgb(var(--lp-colors-neutral-50));
  border-radius: 4px;
`;

export const InfoWrapper = styled.span({
  cursor: 'help',
  '& svg': { width: 18, height: 18 },
});
