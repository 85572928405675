import { Control, useController } from 'react-hook-form';

import { DateField } from '@limepayments/cosmic';

export interface DateProps {
  id?: string;
  control: Control;
  name: 'dateOfBirth';
  label: string;
  defaultValue: string;
  rules?: object;
  type?: string;
  required?: boolean;
  testId?: string;
  placeholder?: string;
}

const mask = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

const Date = (props: DateProps) => {
  const { label, type, required, testId, placeholder, ...otherProps } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController(otherProps);

  return (
    <DateField
      {...inputProps}
      label={label}
      placeholder={placeholder}
      required={required}
      error={error?.message ?? ''}
      type={type ?? 'text'}
      helperText={error ? error.message : ''}
      testId={testId}
      maskPlaceholder="DD/MM/YYYY"
      mask={mask}
      inputMode="numeric"
    />
  );
};

export default Date;
