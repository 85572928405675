import '@limepayments/cosmic/build/css/reset.css';
import '@limepayments/cosmic/build/css/styles.css';

import { getPaymentConfig } from 'api/api';
import { getMerchantName } from 'api/utils';
import { useConfig } from 'lib/configContext';
import { SET_CONFIG } from 'lib/reducers';
import { INSTORE_STEPS, hexToRGB, loadScripts, toSearchParams } from 'lib/utils';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Global } from '@emotion/react';

import OrderPayment from './OrderPayment';
import Registration from './Registration';

function Instore() {
  const [merchantName] = useState(() => getMerchantName());
  const { state, dispatch: dispatchConfig } = useConfig();

  useEffect(() => {
    (async () => {
      const { apiBaseUri: apiBaseUriParams, token } = toSearchParams(window.location.search);
      const apiBaseUriParam = Array.isArray(apiBaseUriParams) ? apiBaseUriParams[0] : apiBaseUriParams;
      const config = await getPaymentConfig(apiBaseUriParam || '', merchantName);
      await loadScripts(config);

      const {
        apiBaseUri,
        authApiKey,
        authDomain,
        tcUrl,
        branding,
        merchantId,
        merchantPublicKey,
        tenantId,
        merchantTradingCountry,
        marketplaceTags,
        merchantTags,
        merchantBusinessDisplayName,
        surchargeQuoteRequired,
      } = config;

      dispatchConfig({
        type: SET_CONFIG,
        payload: {
          apiBaseUri,
          authApiKey,
          authDomain,
          merchantId,
          branding,
          tcUrl,
          token,
          step: INSTORE_STEPS.Register,
          merchantPublicKey,
          tenantId,
          merchantTradingCountry,
          isLaddrB2C: marketplaceTags.includes('Laddr'),
          isLaddrTF: merchantTags.includes('LaddrToolsFinance'),
          merchantBusinessDisplayName,
          surchargeQuoteRequired,
          useCheckoutV2: merchantTags.includes('CheckoutV2') || marketplaceTags.includes('CheckoutV2'),
        },
      });
    })();
  }, [dispatchConfig, merchantName]);

  const primaryColor = useMemo(
    () => (state?.branding.primaryColor ? hexToRGB(state.branding.primaryColor) : ''),
    [state?.branding],
  );

  return (
    <BrowserRouter>
      <Global styles={{ body: { '--lp-colors-primary': primaryColor || '0,22,209' } }} />
      <Helmet>
        <link rel="icon" href={state?.branding.iconUri || '/favicon.ico?v=1'} />
      </Helmet>
      <Routes>
        <Route path="/:merchant/join" element={<Registration />} />
        <Route path="/:merchant/invoice/:orderId" element={<OrderPayment />} />
        <Route path="/:merchantName" element={<Navigate to={`/${merchantName}/join`} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Instore;
