import smoothScroll from 'smoothscroll-polyfill';

smoothScroll.polyfill();

const scrollToView = (selector: string): void => {
  const errorMsgEl = document.querySelector('.error-msg');
  errorMsgEl?.scrollIntoView({ behavior: 'smooth' });
};

export default scrollToView;
