import dayjs from 'dayjs';
import { formatPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const DATE_FORMAT = 'DD/MM/YYYY';

export const validateDob = (value: string): string | boolean => {
  if (dayjs(value, DATE_FORMAT).format(DATE_FORMAT) === value) {
    return true;
  }
  return 'Please enter valid date';
};

export const validateEmail = (value: string): string | boolean => {
  /* eslint-disable */
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value) ? true : 'Please enter valid email';
};

export const validateMobileNumber = (value: string): string | boolean => {
  const format = formatPhoneNumber(value);
  const isFixedLine = format.includes('(');
  return isPossiblePhoneNumber(value) && !isFixedLine ? true : 'Please enter valid mobile number';
}


export const validateAge = (value: string): string | boolean => {
  const today = dayjs(new Date());
  const dob = dayjs(value, DATE_FORMAT);
  const age = today.diff(dob, 'year');
  if (age >= 18) {
    return true;
  }
  return 'Must be over 18';
};

