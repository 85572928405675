import styled from '@emotion/styled';

export const OrderPaymentWrapper = styled.div`
  .heading {
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
  .messageHeading {
    margin: 0;
  }
  .messageText {
    margin-bottom: 0;
  }
`;

export const Header = styled.header`
  padding: 15px 22px;
  box-shadow: var(--lp-shadows-medium);
  min-height: 55px;
  img {
    width: auto;
    height: auto;
    max-height: 40px;
  }
`;

export const Main = styled.div`
  max-width: 768px;
  margin: 0 auto;
  padding: 24px 16px 0;
  font-weight: normal;
`;

export const Alert = styled.div`
  margin-bottom: 16px;
`;

export const Success = styled.div`
  text-align: center;
  img {
    margin: 0 auto;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
