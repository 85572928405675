import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .heading {
    margin-top: 4px;
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
