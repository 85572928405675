import { useConfig } from 'lib/configContext';
import { SET_CONFIG } from 'lib/reducers';
import { INSTORE_STEPS } from 'lib/utils';
import { useEffect } from 'react';

import Order from './Order';
import Payment from './Payment';

const OrderPayment = () => {
  const {
    state: { apiBaseUri, step, merchantBusinessDisplayName } = { apiBaseUri: null, merchantBusinessDisplayName: null },
    dispatch: dispatchConfig,
  } = useConfig();

  useEffect(() => {
    document.title = merchantBusinessDisplayName ? `${merchantBusinessDisplayName} Payment` : '';
  }, [merchantBusinessDisplayName]);

  useEffect(() => {
    if (apiBaseUri) {
      dispatchConfig({
        type: SET_CONFIG,
        payload: {
          step: INSTORE_STEPS.Order,
        },
      });
    }
  }, [dispatchConfig, apiBaseUri]);

  return step === INSTORE_STEPS.Order ? <Order /> : step === INSTORE_STEPS.Payment ? <Payment /> : null;
};

export default OrderPayment;
