import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Heading, Illustration, Message, Text } from '@limepayments/cosmic';

import { PayOrderRequest, PayOrderResponse, payOrder, resolveThreeDSAuthorisationRequired } from '../api/api';
import { useConfig } from '../lib/configContext';
import { useOrder } from '../lib/orderContext';
import { CheckoutErrorEventType, CheckoutEventType, Order } from '../lib/types';
import { PAYMENT_TITLE } from './Constants';
import { Alert, Header, Main, OrderPaymentWrapper, SpinnerWrapper, Success } from './Payment.styles';
import Spinner from './Spinner';

const toPaymentTypeAmountsMap = (paymentTypeAmounts?: Order['paymentTypeAmounts']) =>
  paymentTypeAmounts?.reduce(
    (paymentTypeAmountsMap, { checkoutPayType: paymentType, amount }) => ({
      ...paymentTypeAmountsMap,
      [paymentType]: amount,
    }),
    {} as { [paymentType: string]: number },
  );

declare global {
  interface Window {
    LimepayCheckout: any;
  }
}

type PaymentData = {
  amount: number;
  currency: 'AUD' | 'NZD';
  payType: 'PayInFull' | 'PayPlan';
  paymentMethodType: 'Card' | 'NetworkToken' | 'DirectDebit' | 'PayTo';
};

const Payment = () => {
  const [LpCheckout] = useState(window.LimepayCheckout);
  const { orderId } = useParams<'orderId'>();
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    state: { apiBaseUri, branding, customToken, merchantId, merchantPublicKey, token, isLaddrTF, useCheckoutV2 },
  } = useConfig();

  const {
    state: { amount, paymentTypeAmounts, currency, request3DSOnPayment, hidePayLaterOption },
  } = useOrder();

  useEffect(() => {
    LpCheckout.init({
      publicKey: merchantPublicKey,
      customerToken: customToken,
      hidePayLaterOption,
      paymentToken: function (paymentTokenId: string, paymentData: PaymentData) {
        const payForOrder = async () => {
          if (!orderId) {
            return false;
          }

          const request3DS = request3DSOnPayment;
          const isCard = useCheckoutV2 || paymentData.paymentMethodType === 'Card';

          const request: PayOrderRequest =
            request3DS && isCard
              ? { PayByCard: { paymentTokenId, request3DS } }
              : { PayByPaymentToken: { paymentTokenId } };

          try {
            setIsLoading(true);
            setPaymentError(null);

            const resolvePayOrder = async (request: PayOrderRequest): Promise<PayOrderResponse> => {
              const response = await payOrder(token, orderId, request);

              if ('ThreeDSAuthorisationRequired' in response) {
                const { ThreeDSAuthorisationRequired: threeDSResponse } = response;

                await resolveThreeDSAuthorisationRequired(threeDSResponse);

                return resolvePayOrder({
                  Confirm3DSComplete: { paymentTokenId, threeDSResponse },
                });
              }

              return response;
            };

            const response = await resolvePayOrder(request);

            if ('PayOrderComplete' in response) {
              console.log('>>> payOrder transactionId: ', response.PayOrderComplete.transactionId);
              setIsLoading(false);
              setIsPaymentSuccess(true);
            } else {
              throw Error('Order invoice payment error');
            }
          } catch (e) {
            console.log('e: ', e);
            setIsLoading(false);
            setIsPaymentSuccess(false);
            setPaymentError(e.message);
          }
        };
        payForOrder();
      },
    });

    LpCheckout.render({
      elementId: 'limepay-cont',
      currency,
      amount,
      paymentTypeAmounts: toPaymentTypeAmountsMap(paymentTypeAmounts),
      paymentType: isLaddrTF ? 'payplan' : 'paycard',
      showPayNow: true,
      showPayPlanSubmit: true,
      primaryColor: branding.primaryColor,
    });

    LpCheckout.errorHandler(function (err: CheckoutErrorEventType) {
      console.log('errorHandler err: ', typeof err, err);
      // Checkout spinner is toggle off when hide called from here, expected behaviour.
      // LpCheckout.hide();
    });

    LpCheckout.eventHandler(function (event: CheckoutEventType) {
      console.log('eventHandler event: ', typeof event, event);
    });
  }, [
    LpCheckout,
    amount,
    paymentTypeAmounts,
    apiBaseUri,
    orderId,
    merchantPublicKey,
    token,
    merchantId,
    customToken,
    currency,
    request3DSOnPayment,
    hidePayLaterOption,
    isLaddrTF,
    branding,
    useCheckoutV2,
  ]);

  useEffect(() => {
    if (isLoading || isPaymentSuccess) {
      // TODO: Checkout spinner gets toggled on, need Checkout UI changes.
      LpCheckout.hide();
    }

    if (!isLoading && !isPaymentSuccess) {
      LpCheckout.show();
    }
  }, [LpCheckout, isLoading, isPaymentSuccess]);

  return (
    <OrderPaymentWrapper>
      <Header>
        <img src={branding?.logoUri} alt={branding?.logoAltText} />
      </Header>
      <Main>
        {paymentError && (
          <Alert>
            <Message dismissalType="manual" type="toast" variant="error">
              <Heading variant="xxs" className="messageHeading">
                Payment failed
              </Heading>
              <Text variant="caption" className="messageText">
                {paymentError}
              </Text>
            </Message>
          </Alert>
        )}

        {isLoading ? (
          <SpinnerWrapper>
            <Spinner width="80px" height="80px" color="#007BFF" />
          </SpinnerWrapper>
        ) : isPaymentSuccess ? (
          <Success>
            <Illustration name="Purchases" size="large" />
            <Heading variant="xs" className="heading">
              Payment successful
            </Heading>
            <Text variant="body-3" className="messageText">
              You can now close this window
            </Text>
          </Success>
        ) : (
          <>
            <Heading variant="md" className="heading">
              {PAYMENT_TITLE}
            </Heading>
          </>
        )}
      </Main>
    </OrderPaymentWrapper>
  );
};

export default Payment;
