import { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';

import { orderReducer } from './reducers';
import { Order, OrderContextType } from './types';

export interface OrderProviderProps {
  initialState?: OrderContextType;
  children: ReactNode;
}

const OrderContext = createContext<{ state: Order; dispatch: Dispatch<any> } | undefined>(undefined);

function OrderProvider({ initialState, children }: OrderProviderProps) {
  const [state, dispatch] = useReducer(orderReducer, initialState);
  const value = { state, dispatch };

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
}

function useOrder() {
  const context = useContext(OrderContext);
  if (context === undefined) {
    throw new Error('Order context is required');
  }
  return context;
}

export { OrderProvider, useOrder };
