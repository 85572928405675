import { sendCustomerVerification, verifyCustomerCodes } from 'api/api';
import { useConfig } from 'lib/configContext';
import { SET_CONFIG, SET_REGISTRATION_FORM, SET_STEP } from 'lib/reducers';
import { useRegistration } from 'lib/registrationContext';
import scrollToView from 'lib/scrollToView';
import { INSTORE_STEPS } from 'lib/utils';
import { MouseEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Heading, Link, Message, Text } from '@limepayments/cosmic';

import * as s from './Verify.styles';
import Input from './VerifyInput';

export interface VerificationData {
  emailVerificationCode: string;
  phoneVerificationCode: string;
}

const Verify = () => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const {
    state: { apiBaseUri, merchantPublicKey },
    dispatch: dispatchConfig,
  } = useConfig();

  const { state: registrationState, dispatch: dispatchRegistration } = useRegistration();
  const { phoneNumber, emailAddress, customerId, emailVerification, phoneVerification } = registrationState;

  const { handleSubmit, control } = useForm();
  const [codeSentTimer, setCodeSentTimer] = useState<number>(0);
  const [now, setNow] = useState<number>(0);

  useEffect(() => {
    if (now === 0) {
      return;
    }

    setCodeSentTimer(5);
    const tick = () =>
      setCodeSentTimer((prev) => {
        if (prev <= 0) {
          clearInterval(timer);
        }
        return prev - 1;
      });
    const timer = setInterval(tick, 1e3);
    return () => {
      clearInterval(timer);
    };
  }, [now]);

  const onSubmit = async (data: VerificationData) => {
    if (!merchantPublicKey) {
      return;
    }
    try {
      setLoading(true);
      const { customToken } = await verifyCustomerCodes(apiBaseUri, merchantPublicKey, {
        customerId,
        emailVerification,
        phoneVerification,
        emailVerificationCode: data.emailVerificationCode,
        phoneVerificationCode: data.phoneVerificationCode,
      });
      if (customToken) {
        dispatchConfig({
          type: SET_CONFIG,
          payload: {
            step: INSTORE_STEPS.KycId,
            customToken,
          },
        });
      }
    } catch (e) {
      setErrorMsg(e?.message || 'Failed to verify codes');
      setLoading(false);
      scrollToView('.error-msg');
    }
  };

  const handleResendCode = async (e: MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      const { emailVerification, phoneVerification } = await sendCustomerVerification(
        apiBaseUri,
        merchantPublicKey,
        customerId,
        {
          emailAddress,
          phoneNumber,
        },
      );
      if (emailVerification && phoneVerification) {
        dispatchRegistration({
          type: SET_REGISTRATION_FORM,
          payload: { ...registrationState, emailVerification, phoneVerification },
        });
        setNow(Date.now());
      }
    } catch (e) {
      setErrorMsg(e.message);
      scrollToView('.error-msg');
    }
  };

  const handleGoBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    dispatchConfig({
      type: SET_STEP,
      payload: INSTORE_STEPS.Register,
    });
  };

  return (
    <s.VerifyWrapper>
      <Heading variant="md" className="heading">
        Verify your contact details
      </Heading>
      <Text className="text" variant="body-2">
        We've sent codes to ***{phoneNumber?.slice(-3)} and {emailAddress}. Please enter them below.
      </Text>

      {errorMsg.length > 0 && (
        <s.MessageBar className="error-msg">
          <Message type="inline" variant="error" dismissalType="none">
            <Text variant="body-2" testId="verifyErrorMsg">
              {errorMsg}
            </Text>
          </Message>
        </s.MessageBar>
      )}

      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <s.InputWrapper top="20px">
          <Input
            control={control}
            name="phoneVerificationCode"
            label="Mobile code"
            defaultValue=""
            rules={{ required: 'Required' }}
            testId="phoneNoCode"
            autoFocus
          />
          <s.ResendLink>
            {codeSentTimer < 1 && (
              <Link size="small" onClick={handleResendCode} testId="resendPhoneNo">
                Resend
              </Link>
            )}
            {codeSentTimer > 0 && <Text variant="body-3">Code sent! Wait {codeSentTimer} seconds to try again</Text>}
          </s.ResendLink>
        </s.InputWrapper>

        <s.InputWrapper top="0">
          <Input
            control={control}
            name="emailVerificationCode"
            label="Email code"
            defaultValue=""
            rules={{ required: 'Required' }}
            testId="emailCode"
          />

          <s.ResendLink>
            {codeSentTimer < 1 && (
              <Link size="small" onClick={handleResendCode} testId="resendEmail">
                Resend
              </Link>
            )}
            {codeSentTimer > 0 && <Text variant="body-3">Code sent! Wait {codeSentTimer} seconds to try again</Text>}
          </s.ResendLink>
        </s.InputWrapper>
        <div>
          <Button
            testId="verifyButton"
            isLoading={loading}
            disabled={loading}
            className="lp-w-full"
            variant="primary"
            size="large"
          >
            Verify
          </Button>
        </div>

        <Button
          testId="goBackButton"
          variant="ghost"
          type="button"
          size="large"
          className="go-back-btn lp-w-full"
          onClick={handleGoBack}
        >
          Go back
        </Button>
      </form>
    </s.VerifyWrapper>
  );
};

export default Verify;
