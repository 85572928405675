import { Heading, Illustration } from '@limepayments/cosmic';

import * as s from './KycSuccess.styles';

const KycSuccess = () => (
  <s.Wrapper>
    <s.ImgWrapper>
      <Illustration name="Verification-Success" />
    </s.ImgWrapper>

    <Heading variant="xs" alignment="center" className="heading">
      Verified successfully
    </Heading>
  </s.Wrapper>
);

export default KycSuccess;
