import { getAprilApiHost, getLimepayApiHost } from 'lib/env';
import { ORDER_STATUS, Order } from 'lib/types';

import { handleResponse } from './utils';

export interface GetPaymentConfigResponse {
  apiBaseUri: string;
  merchantId: string;
  tenantId: string;
  authApiKey: string;
  merchantPublicKey: string;
  authDomain: string;
  branding: {
    logoUri?: string;
    logoAltText?: string;
    iconUri?: string;
    primaryColor?: string;
  };
  veryGoodSecurityVaultEnvironment: 'live' | 'sandbox';
  veryGoodSecurityVaultId: string;
  tcUrl: string;
  merchantTradingCountry: 'AU' | 'NZ';
  marketplaceTags: Array<'Laddr' | 'CheckoutV2' | 'CheckoutV3'>;
  merchantTags: Array<'CheckoutV2' | 'CheckoutV3' | 'LaddrToolsFinance'>;
  merchantBusinessDisplayName: string;
  surchargeQuoteRequired?: boolean;
}

export interface ThreeDSAuthorisationRequired {
  threeDSAuthorisationId: string;
  redirectURL: string;
}

export type PayOrderRequest =
  | {
      PayByPaymentToken: {
        paymentTokenId: string;
      };
    }
  | {
      PayByCard: {
        paymentTokenId: string;
        request3DS?: boolean;
      };
    }
  | {
      Confirm3DSComplete: {
        paymentTokenId: string;
        threeDSResponse: {
          threeDSAuthorisationId: string;
        };
      };
    };

export type PayOrderResponse =
  | {
      PayOrderComplete: {
        transactionId: string;
      };
    }
  | {
      ThreeDSAuthorisationRequired: ThreeDSAuthorisationRequired;
    };

export type ItemType = {
  amount: number;
  currency: 'AUD' | 'NZD';
  description: string;
  sku: string | null;
  quantity: number;
  imageUrl: string | null;
};

type StandardFieldType = {
  customLabel: string | null;
  visibleForCustomer: boolean;
};

type StandardFields = {
  orderNumberField: StandardFieldType;
  descriptionField: StandardFieldType;
  customerNameField: StandardFieldType;
  customerPhoneField: StandardFieldType;
};

type CustomField = {
  key: string;
  label: string;
  required: boolean;
  visibleForCustomer: boolean;
};

export interface CustomSettings {
  standardFields: StandardFields;
  customFields: CustomField[] | null;
  orderItemsEnabled: boolean;
  gstIncluded: boolean;
}

type Meta = {
  [key: string]: string;
};

export interface GetOrderInvoiceResponse {
  customToken: string;
  merchantPublicKey: string;
  hidePayLaterOption?: boolean;
  order: {
    status: ORDER_STATUS;
    amount: number;
    paymentTypeAmounts?: Order['paymentTypeAmounts'];
    currency: string;
    description: string;
    items: ItemType[];
    taxIncludedAmount: number | null;
    customerName: string | null;
    customerEmail: string | null;
    phoneNo: string | null;
    request3DSOnPayment: boolean;
    metadata: Meta | null;
  };
}

export interface CreateOrGetCustomerRequest {
  emailAddress: string;
  phoneNumber: string;
}

export interface SendCustomerVerificationRequest {
  emailAddress: string;
  phoneNumber: string;
}

export interface SendCustomerVerification {
  emailVerification: {
    customerId: string;
    emailAddress: string;
    createdAt: string;
  };
  phoneVerification: { customerId: string; phoneNumber: string; createdAt: string };
}

export interface VerifyCustomerRequest {
  customerId: string;
  emailVerification: object;
  phoneVerification: object;
  emailVerificationCode: string;
  phoneVerificationCode: string;
}

export interface VerifyCustomer {
  verificationTokens: {
    emailToken: string;
    phoneToken: string;
  };
  customToken: string;
  error: string;
}

export const getPaymentConfig = async (host: string, merchantName: string): Promise<GetPaymentConfigResponse> => {
  const apiHost = await getLimepayApiHost();

  return fetch(`${apiHost}/config/customer?m=${merchantName}`, {
    method: 'GET',
  }).then(handleResponse);
};

export const createOrGetCustomer = (
  host: string,
  publicKey: string,
  payload: CreateOrGetCustomerRequest,
): Promise<string> =>
  fetch(`${host}/customers`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);

export const sendCustomerVerification = (
  host: string,
  publicKey: string,
  customerId: string,
  payload: SendCustomerVerificationRequest,
): Promise<SendCustomerVerification> =>
  fetch(`${host}/customers/${customerId}/verification`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);

export const verifyCustomerCodes = (
  host: string,
  publicKey: string,
  payload: VerifyCustomerRequest,
): Promise<VerifyCustomer> =>
  fetch(`${host}/customers/${payload.customerId}/verification/codes`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);

export const getOrderInvoice = (
  host: string,
  token: string,
  merchantId: string,
  merchantOrderId: string,
): Promise<GetOrderInvoiceResponse> =>
  fetch(`${host}/orders/invoices/${merchantId}/${merchantOrderId}?invoiceToken=${token}`, {
    method: 'GET',
  }).then(handleResponse);

export const getPublicOrderCustomSettings = (
  host: string,
  publicKey: string,
  merchantId: string,
): Promise<CustomSettings> =>
  fetch(`${host}/orders/${merchantId}/custom-settings`, {
    method: 'GET',
    headers: new Headers({
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);

export const payOrder = async (token: string, orderId: string, payload: PayOrderRequest): Promise<PayOrderResponse> =>
  fetch(`${await getAprilApiHost()}/orders/${orderId}/pay`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  }).then((res) => handleResponse(res, ({ message, detail }) => detail || message));

export const resolveThreeDSAuthorisationRequired = (threeDSAuthorisationRequired: ThreeDSAuthorisationRequired) =>
  new Promise((resolve, reject) =>
    window.LimepayCheckout.handlePaymentActionRequired(threeDSAuthorisationRequired, resolve, reject),
  );
