import { useConfig } from 'lib/configContext';
import { SET_STEP } from 'lib/reducers';
import { useRegistration } from 'lib/registrationContext';
import { INSTORE_STEPS } from 'lib/utils';
import { MouseEvent, useEffect } from 'react';

import { Button } from '@limepayments/cosmic';

import KycSuccess from './KycSuccess';
import Register from './Register';
import { Header, Main, RegistrationWrapper } from './Registration.styles';
import Verify from './Verify';

declare global {
  interface Window {
    LimepayIdentity: any;
  }
}

const Registration = () => {
  const { state: { branding, step, customToken, merchantPublicKey } = {}, dispatch: dispatchConfig } = useConfig();
  const { state: regState } = useRegistration();

  useEffect(() => {
    document.title = 'Register';
  }, []);

  useEffect(() => {
    if (step === INSTORE_STEPS.KycId) {
      window.LimepayIdentity.render(
        {
          elementId: 'identityCont',
          publicKey: merchantPublicKey,
          customToken,
          firstName: regState.firstName,
          middleName: regState.middleName,
          lastName: regState.lastName,
          address: regState.address,
          addressIdentifier: regState.addressIdentifier,
          dateOfBirth: regState.dateOfBirth,
          primaryColour: branding?.primaryColor,
        },
        () => {
          console.log('KYC denied or attempts exhausted.');
        },
        () => {
          window.LimepayIdentity?.hide();
          dispatchConfig({
            type: SET_STEP,
            payload: INSTORE_STEPS.KycSuccess,
          });
        },
      );
    }
  }, [customToken, dispatchConfig, merchantPublicKey, regState, step, branding]);

  const handleGoBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    dispatchConfig({
      type: SET_STEP,
      payload: INSTORE_STEPS.Register,
    });
  };

  let Step;
  switch (step) {
    case INSTORE_STEPS.Register:
      Step = <Register />;
      break;

    case INSTORE_STEPS.Verify:
      Step = <Verify />;
      break;

    case INSTORE_STEPS.KycId:
      Step = (
        <>
          <div id="identityCont"></div>
          <Button
            testId="goBackButton"
            variant="ghost"
            type="button"
            size="large"
            className="go-back-btn"
            onClick={handleGoBack}
          >
            Go back
          </Button>
        </>
      );
      break;

    case INSTORE_STEPS.KycSuccess:
      Step = <KycSuccess />;
      break;
  }

  return (
    <RegistrationWrapper>
      <Header>
        <img src={branding?.logoUri} alt={branding?.logoAltText} />
      </Header>
      <Main>{Step}</Main>
    </RegistrationWrapper>
  );
};

export default Registration;
