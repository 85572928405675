import styled from '@emotion/styled';

export const RegistrationWrapper = styled.div`
  .heading {
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
  button.go-back-btn {
    color: #191e33;
    width: 100%;
    &:hover {
      color: #191e33;
    }
  }
`;

export const Header = styled.header`
  padding: 15px 22px;
  box-shadow: var(--lp-shadows-medium);
  min-height: 55px;
  img {
    width: auto;
    height: auto;
    max-height: 40px;
  }
`;

export const Main = styled.div`
  max-width: 768px;
  margin: 0 auto;
  padding: 32px 40px;
`;
